import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthRepository } from './data/repositories/auth.repository';
import { filter, map } from 'rxjs';

export const loggedOutGuard: CanActivateFn = () => {
  const auth = inject(AuthRepository);

  return auth.loading$.pipe(
    // Hold until done loading
    // TODO - add timer to fail if no load within 10s
    filter((loading) => !loading),
    map(() => !auth.loggedIn)
  );
};
