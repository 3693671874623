import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { AuthRepository } from '../data/repositories/auth.repository';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, startWith } from 'rxjs';

interface Branding {
  name: string;
  logo?: string;
  mobileLogo?: string;
  tagline?: string;
  url?: string;
  favicon?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-brand-header',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatTooltipModule, RouterModule],
  templateUrl: './brand-header.component.html',
  styleUrl: './brand-header.component.scss',
})
export class BrandHeaderComponent {
  #auth = inject(AuthService);
  #authRepo = inject(AuthRepository);
  #breakpointObs = inject(BreakpointObserver);
  #dialog = inject(MatDialog);
  #router = inject(Router);
  loggedIn$ = this.#authRepo.loggedIn$;
  isOrgAdmin$ = this.#authRepo.isOrgAdmin$;
  isMobile$ = this.#breakpointObs.observe([Breakpoints.Handset]).pipe(
    untilDestroyed(this),
    map((state) => state.matches),
    // Start as mobile for less flash on phone
    startWith(true)
  );

  logo$ = this.isMobile$.pipe(
    map((mobile) =>
      mobile && this.brand.mobileLogo ? this.brand.mobileLogo : this.brand.logo
    )
  );

  brand: Branding = environment.brand;

  @Input()
  showTitle = true;

  @Input()
  showTagline = !!this.brand.tagline;

  @Input()
  showAuth = true;

  logout() {
    this.#auth.logout();
    this.#router.navigateByUrl('/');
  }

  login() {
    this.#dialog.open(LoginDialogComponent);
  }
}
