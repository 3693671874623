<h1 mat-dialog-title>Set a New Password</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>
        New Password
      </mat-label>
      <input matInput type="password" #password formControlName="password" />
    </mat-form-field>
    <mat-password-strength #passwordStrength [password]="password.value"
      [enableSpecialCharRule]="false"></mat-password-strength>
    <mat-form-field>
      <mat-label>
        Confirm Password
      </mat-label>
      <input matInput type="password" formControlName="confirmPassword" />
    </mat-form-field>
    <mat-password-strength-info [passwordComponent]="passwordStrength"></mat-password-strength-info>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    (click)="close()">
    Cancel
  </button>
  <button
    mat-raised-button
    [disabled]="form.invalid || resetting">
    {{resetting ? 'Saving...' : 'Set Password'}}
  </button>
</div>