import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-public-theme',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './public-theme.component.html',
  styleUrl: './public-theme.component.scss',
})
export class PublicThemeComponent {
  // TODO - pull this from route info
  @Input()
  className = 'single-post';
}
