<div class="public-theme">
  <div [ngClass]="className">
    <div class="wrap">
      <div class="outer">
        <div class="content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
