import { AbstractControl } from '@angular/forms';

export const sameAs = (name: string) => {
  return (ctrl: AbstractControl) => {
    // If there is no parent, we can't find the comparison
    if (!ctrl.parent) return null;
    const compareCtrl = ctrl.parent.get(name);
    if (!compareCtrl) {
      throw new Error(`Unable to find form control "${name}" to compare to`);
    }
    if (ctrl.value === compareCtrl.value) return null;
    return {
      sameAs: { value: ctrl.value },
    };
  };
};
