import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { SupabaseService } from './supabase.service';
import { DisplayProduct } from './data/types/product.types';

export const displayProductResolver: ResolveFn<
  DisplayProduct | null | undefined
> = (route, _state) => {
  const sb = inject(SupabaseService);
  const productId = route.paramMap.get('productId');
  return sb.fn<DisplayProduct>('get-product-info', {
    productId,
  });
};
