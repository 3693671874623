import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { hasUppercase } from '../validators/hasUppercase.validator';
import { hasLowercase } from '../validators/hasLowercase.validator';
import { hasNumber } from '../validators/hasNumber.validator';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { SupabaseService } from '../supabase.service';
import { HotToastService } from '@ngneat/hot-toast';
import { MatInputModule } from '@angular/material/input';
import { sameAs } from '../validators/sameAs.validator';
import { CommonModule } from '@angular/common';

const PASSWORD_VALIDATORS = [
  Validators.required,
  Validators.minLength(8),
  hasUppercase,
  hasLowercase,
  hasNumber,
];

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatPasswordStrengthModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent {
  #ref = inject(MatDialogRef<ResetPasswordComponent>);
  #sb = inject(SupabaseService);
  #toast = inject(HotToastService);

  form = new FormGroup({
    password: new FormControl('', {
      nonNullable: true,
      validators: PASSWORD_VALIDATORS,
    }),
    confirmPassword: new FormControl('', {
      nonNullable: true,
      validators: [...PASSWORD_VALIDATORS, sameAs('password')],
    }),
  });

  resetting = false;

  async reset() {
    if (!this.#sb.user) {
      console.error(
        '[reset] somehow got to reset password without a valid code, cannot do anything'
      );
      this.close();
      return;
    }
    const { password, confirmPassword } = this.form.value;
    if (password !== confirmPassword) {
      // TODO - make this more obvious
      this.#toast.error(
        'Passwords do not match. Please ensure your passwords match before resetting'
      );
      return;
    }
    this.resetting = true;
    const { error } = await this.#sb.client.auth.updateUser({
      password,
    });
    if (error) {
      // TODO - send to sentry
      this.#toast.error(
        'Error resetting password. Please try again or contact support if the problem persists'
      );
      return;
    }
    this.#toast.success(`Your password has successfully been reset!`);
    this.close();
  }

  close() {
    this.#ref.close();
  }
}
