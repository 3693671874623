import { PLATFORM_ID, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from './services/auth.service';
import { filter, map, race, timer } from 'rxjs';
import { isPlatformServer } from '@angular/common';

export const loggedInGuard: CanActivateFn = () => {
  const platformId = inject(PLATFORM_ID);
  // We don't yet handle this on server, so until we do just route forward
  // and let frontend handle it
  if (isPlatformServer(platformId)) return true;
  const auth = inject(AuthService);

  return race(timer(1000), auth.isLoggedIn$().pipe(filter(Boolean))).pipe(
    map(Boolean)
  );
};
