import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from './data/types/user.interface';
import { AuthRepository } from './data/repositories/auth.repository';

const { chatwoot } = environment;

@Injectable({
  providedIn: 'root',
})
export class ChatwootService {
  #auth = inject(AuthRepository);
  #doc = inject(DOCUMENT);
  #sdk: any = null;

  constructor(
    @Inject(PLATFORM_ID)
    platformId: any
  ) {
    if (isPlatformBrowser(platformId)) {
      this.#init();
    }
  }

  setUser(user: User | null) {
    if (user) {
      this.#sdk.setUser(user.id, user);
    } else {
      this.#sdk.reset();
    }
  }

  #init() {
    this.#insertScript();
  }

  #insertScript() {
    const BASE_URL = 'https://app.chatwoot.com';
    const doc = this.#doc;
    const g = doc.createElement('script');
    const s = doc.getElementsByTagName('script')[0];
    g.src = BASE_URL + '/packs/js/sdk.js';
    g.defer = true;
    g.async = true;
    s!.parentNode!.insertBefore(g, s);
    g.onload = () => {
      window.addEventListener('chatwoot:ready', () => {
        this.#ready();
      });
      const sdk = (window as any).chatwootSDK;
      sdk.run({
        ...chatwoot,
        baseUrl: BASE_URL,
      });
    };
  }

  #ready() {
    this.#sdk = (window as any).$chatwoot;
    this.#auth.user$.subscribe((u) => {
      this.setUser(u);
    });
  }
}
