import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { loggedInGuard } from './logged-in.guard';
import { loggedOutGuard } from './logged-out.guard';
import { PublicThemeComponent } from './public-theme/public-theme.component';
import { displayProductResolver } from './display-product.resolver';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        component: PublicThemeComponent,
        path: '',
        children: [
          {
            path: '',
            component: WelcomePageComponent,
          },
          {
            path: 'login',
            component: LoginComponent,
            canActivate: [loggedOutGuard],
          },
          {
            path: 'product',
            children: [
              {
                path: ':productId/purchased',
                // TODO - remove this once Supabase Auth in SSR is fixed
                // canActivate: [loggedInGuard],
                loadComponent: () =>
                  import(
                    './purchased-content-view/purchased-content-view.component'
                  ).then((c) => c.PurchasedContentViewComponent),
              },
              {
                path: ':productId',
                resolve: {
                  product: displayProductResolver,
                },
                loadComponent: () =>
                  import('./product-display/product-display.component').then(
                    (c) => c.ProductDisplayComponent
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'admin',
        canActivate: [loggedInGuard],
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: 'calculators',
    loadChildren: () =>
      import('./calculators/calculators.module').then(
        (m) => m.CalculatorsModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      bindToComponentInputs: true,
      scrollPositionRestoration: 'enabled',
      // TODO - look into this
      // enableViewTransitions: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
