import { Component, afterNextRender, inject } from '@angular/core';
import { AuthRepository } from './data/repositories/auth.repository';
import { AnalyticsService } from './analytics.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { Subject, combineLatest, filter, takeUntil } from 'rxjs';
import { notFalsy } from './data/utils/filterFns';

import { AngularFaviconService } from 'angular-favicon';
import { environment } from 'src/environments/environment';
import { ChatwootService } from './chatwoot.service';
import { MetaService } from './meta.service';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  #dialog = inject(MatDialog);
  #route = inject(ActivatedRoute);
  #favicon = inject(AngularFaviconService);
  #meta = inject(MetaService);
  #mis = inject(MatIconRegistry);

  constructor(
    private authRepo: AuthRepository,
    // Injected just to start
    _analytics: AnalyticsService,
    _chatwoot: ChatwootService
  ) {
    afterNextRender(() => {
      this.#checkPasswordReset();
    });
  }

  ngOnInit() {
    const { brand } = environment;
    if (brand.favicon) {
      this.#favicon.setFavicon(brand.favicon);
    }
    this.#meta.set({
      title: brand.name,
      image: brand.logo,
      // TODO - Add description and base url
    });
    this.#mis.setDefaultFontSetClass('material-symbols-outlined');
  }

  #checkPasswordReset() {
    const matched$ = new Subject<void>();
    combineLatest([
      this.authRepo.user$.pipe(filter(notFalsy)),
      this.#route.queryParams,
    ])
      .pipe(takeUntil(matched$))
      .subscribe(([_, params]) => {
        if (params['reset_password']) {
          matched$.next();
          matched$.complete();
          this.#dialog.open(ResetPasswordComponent, {
            disableClose: true,
            minWidth: '350px',
            maxWidth: '95%',
          });
        }
      });
  }
}
