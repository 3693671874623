<div class="content-thin">
  <h2 class="text-center single-post__title">Welcome to {{brand.name}}!</h2>
  <div class="summary single-post__description body_large">
    <!-- TODO - make dynamic -->
    <p>Here you can browse all my guides and other educational content, as well as quickly access any products you've already purchased!</p>
    <p
      *ngIf="brand.url">
      If you're curious about what else I offer, check out my main site at
      <a [href]="brand.url">{{brand.url}}</a>!
    </p>
  </div>
  <div class="products">
    <h3 class="text-center single-post__title">Available Products</h3>
    <div class="flex-container content">
      @for (product of products$ | async; track product.slug) {
        <div
          class="product card_small">
          <a
            *ngIf="product.cover_asset"
            [routerLink]="['product', product.slug]"
            class="card_small__img">
            <picture>
              <img [src]="imageSrc(product.cover_asset)" />
            </picture>
          </a>
          <div class="card_small__body">
            <h3 class="card_small__title lead_medium">
              <a [routerLink]="['product', product.slug]">
                {{product.name}}
              </a>
            </h3>
            <div
              *ngIf="product.summary?.raw"
              class="body_normal card_small__description" [innerHTML]="product.summary.raw">
            </div>
            <div class="card_small__info">
              <div class="card_small__stats">
                <!-- TODO - use small icon here instead of dollar symbol -->
                <p>
                  ${{product.price}}
                </p>
              </div>
              <div class="card_small__bookmark">
                <a
                  mat-link-button
                  [routerLink]="['product', product.slug]">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      } @empty {
        <div class="loading">
          <app-loader width="300px"></app-loader>
        </div>
      }
    </div>
  </div>
</div>