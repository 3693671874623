import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SupabaseService } from '../supabase.service';
import { DisplayProduct } from '../data/types/product.types';
import { CloudinaryService } from '../cloudinary.service';
import { Asset } from '../data/types/asset.interface';
import { Json } from '../supabase.types';
import { MatButtonModule } from '@angular/material/button';
import { LoaderComponent } from '../loader/loader.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-welcome-page',
  standalone: true,
  imports: [CommonModule, MatButtonModule, LoaderComponent, RouterModule],
  templateUrl: './welcome-page.component.html',
  styleUrl: './welcome-page.component.scss',
})
export class WelcomePageComponent {
  #cloudinary = inject(CloudinaryService);
  #sb = inject(SupabaseService);

  brand = environment.brand;
  #products$ = new BehaviorSubject<DisplayProduct[]>([]);

  products$ = this.#products$.asObservable();

  async ngOnInit() {
    const products = await this.#sb.fn<DisplayProduct[]>(
      'all-available-products'
    );
    if (products?.length) {
      this.#products$.next(products);
    }
  }

  imageSrc(asset: Asset | Json) {
    return this.#cloudinary.url(asset as Asset);
  }
}
