<header [class.mobile]="isMobile$ | async">
  <div class="flex-spacer" *ngIf="!showTitle && !showTagline && showAuth">
  </div>
  <div
    *ngIf="brand.logo"
    class="logo">
    <a *ngIf="brand.url; else imageOnly"
      target="_blank"
      title="Visit {{brand.name}}"
      [href]="brand.url">
      <img [src]="logo$ | async" />
    </a>
    <ng-template #imageOnly>
      <img [src]="logo$ | async" />
    </ng-template>
  </div>
  <div
    *ngIf="showTitle"
    class="title">
    <!-- TODO - Link this if no logo -->
    <h1>{{brand.name}}</h1>
  </div>
  <h5 *ngIf="showTagline" class="tagline">
    {{brand.tagline}}
  </h5>
  <div *ngIf="showAuth" class="auth" [class.logged-in]="loggedIn$ | async">
    <div *ngIf="loggedIn$ | async; else loginTpl">
      <a
        *ngIf="isOrgAdmin$ | async"
        mat-button
        color="primary"
        matTooltip="Manage your products and content"
        routerLink="/admin/products">
        Admin
      </a>
      <button
        mat-button
        (click)="logout()">
        Logout
      </button>
    </div>
    <ng-template #loginTpl>
      <button
        mat-button
        color="primary"
        (click)="login()">
        Login
      </button>
    </ng-template>
  </div>
</header>